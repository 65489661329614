import version from "../../../version.json";

import {
    IconCheckedCircle,
    IconCog,
    IconPen,
    IconUser,
} from "components/Icons";

const NavigationBar = () => {
    return (
        <nav>
            <div>
                <h1>AUTVOICE</h1>
                <p>{version.version}</p>
            </div>
            <div>
                <IconCog />
                <IconCheckedCircle />
                <IconPen />
                <IconUser />
            </div>
        </nav>
    );
};

export default NavigationBar;
