import React, { useState } from "react";

import "./App.css";
import Layout from "components/Layout/Layout";
import { Pages } from "components/Document/Documents";

export default function App() {
    return (
        <Layout>
            <Pages />
        </Layout>
    );
}
