import { ReactNode } from "react";
import Header from "./Header";
import Footer from "./Footer";

import { invoicer } from "data/invoicer";

const Layout = ({ children }: { children: ReactNode }) => {
    return (
        <div style={{ fontFamily: invoicer.formats.general.fonts.family }}>
            <Header />
            <div className="layout">{children}</div>
            <Footer />
        </div>
    );
};

export default Layout;
