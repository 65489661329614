import { LogoSvg } from "../LogoSvg";
import { invoicer } from "../../data/invoicer";

export function Header() {
    return (
        <div className="header">
            <LogoSvg />
            <div className="brand">
                <a
                    href={invoicer.websiteLink.target}
                    target="_blank"
                    rel="noreferrer"
                >
                    <div className="brand-name bold">
                        {invoicer.websiteLink.text}
                    </div>
                    <div className="brand-description">
                        {invoicer.fields.join(" | ")}
                    </div>
                </a>
            </div>
        </div>
    );
}
