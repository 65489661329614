import { ReactNode } from "react";
import NavigationBar from "components/Layout/Navigation/NavigationBar";

const Header = () => {
    return (
        <header className="header">
            <NavigationBar />
        </header>
    );
};

export default Header;
