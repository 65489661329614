import { Helmet } from "react-helmet";
import { getTitle } from "helpers/title";

interface LocalHelmetProps {
    invoiceNumber: string;
    companyShortName: string;
}

const LocalHelmet = (props: LocalHelmetProps) => {
    return (
        <Helmet>
            <title>
                {("0" + (new Date().getMonth() + 1)).slice(-2)}-
                {/* {getTitle(props.invoiceNumber)} */}
                {props.invoiceNumber}-{props.companyShortName}-rechnung-
                {new Date().getFullYear().toString()}
            </title>
        </Helmet>
    );
};

export default LocalHelmet;
