import React, { ChangeEvent, ReactNode, useEffect, useState } from "react";

import { MyAddress } from "./MyAddress";

import { ICompany } from "../../../data/companies";
import { useCompanies } from "../../../hooks/useCompanies";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import LocalHelmet from "../../Layout/LocalHelmet";

interface AddressProps {
    invoiceNumber: string;
}

export function Address(props: AddressProps) {
    const { companies, updateOrCreateCompany } = useCompanies();
    const [company, setCompany] = useState<ICompany>({
        name: "Company Name",
        uid: "UIDUIDUID",
        shortName: "",
        address: "Street Name",
        secondAddressLine: "",
        postalCode: "Postal Code",
        city: "Town & Country",
    });

    useEffect(() => {
        updateOrCreateCompany(company);
    }, [company]);

    function onChange(event: ChangeEvent<HTMLSelectElement>) {
        const newCompany = companies.find(
            (company: ICompany) => company.shortName === event.target.value
        );

        if (newCompany) {
            setCompany(newCompany);
        }
    }

    function renderOptions(): ReactNode[] {
        const result: ReactNode[] = [];
        companies.forEach((company: ICompany) => {
            result.push(
                <option value={company.shortName} key={company.shortName}>
                    {company.shortName}
                </option>
            );
        });

        return result;
    }

    function onCompanyNameChange(event: ContentEditableEvent) {
        setCompany((company: ICompany): ICompany => {
            return {
                ...company,
                name: event.target.value,
            };
        });
    }

    return (
        <>
            <LocalHelmet
                invoiceNumber={props.invoiceNumber}
                companyShortName={company.shortName}
            />
            <div className="address-wrapper">
                <div className="address-customer">
                    <div
                        className="custom-select address-dropdown"
                        id="sel-address-select-dropdown"
                    >
                        <select onChange={onChange}>{renderOptions()}</select>
                    </div>

                    {company.name && (
                        <ContentEditable
                            html={company.name}
                            onChange={onCompanyNameChange}
                            className="bold"
                        />
                    )}
                    {company.uid && (
                        <div contentEditable className="bold">
                            {company.uid}
                        </div>
                    )}
                    {company.address && (
                        <div
                            contentEditable
                            className="street"
                            onChange={(e) => console.log(e)}
                        >
                            {company.address}
                        </div>
                    )}
                    {company.secondAddressLine && (
                        <div
                            contentEditable
                            className="street"
                            onChange={(e) => console.log(e)}
                        >
                            {company.secondAddressLine}
                        </div>
                    )}
                    {company.postalCode && (
                        <div contentEditable className="postal-city">
                            {company.postalCode} {company.city}
                        </div>
                    )}
                </div>

                <MyAddress />
            </div>
        </>
    );
}
