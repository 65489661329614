import { useEffect, useState } from 'react';
import { ICompany } from "../data/companies";

export function useCompanies() {
    const [companies, setCompanies] = useState<ICompany[]>([]);

    useEffect(() => {
        fetch("https://invoice.philippschmid.me/cmps/companies.json", {

        })
            .then((response) => response.json())
            .then((data) => {
                setCompanies(data);
            })
            .catch((error) => console.log(error));
    }, []);

    function updateOrCreateCompany(company?: ICompany) {
        if (!company) {
            return;
        }


        console.log(company);

        // this will set or create a new company
        setCompanies((companies: ICompany[]): ICompany[] => {
             companies.map((c: ICompany, index: number) => {
                 // if (c.uid === company.uid) {
                 //     companies[index] = company;
                 // }
                 //
                 // return
            });

             return companies;
        });
    }

    return {
        companies,
        updateOrCreateCompany,
    };
}