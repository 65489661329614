import React, { useState } from "react";
import ContentEditable, { ContentEditableEvent } from "react-contenteditable";
import { invoicer } from "../../../data/invoicer";

interface InvoiceNumberProps {
    invoiceNumber: string;
    onChange: (invoiceNumber: string) => void;
}

export function InvoiceNumber(props: InvoiceNumberProps) {
    const [period, setPeriod] = useState<string>("Januar - Juni");

    function onInvoiceNumberChange(event: ContentEditableEvent) {
        props.onChange(event.target.value);
    }

    function onPeriodChange(event: ContentEditableEvent) {
        setPeriod(event.target.value);
    }

    return (
        <>
            <div className="invoice-wrapper">
                <div className="invoice-number" id="invoice-number">
                    <span className="invoice-title">Rechnungsnummer: </span>
                    <span className="current-year invoice-number-year">
                        {new Date().getFullYear()}
                    </span>
                    <span>/</span>
                    <span>{("0" + (new Date().getMonth() + 1)).slice(-2)}</span>
                    <span>
                        {invoicer.formats.invoiceNumber.serialNumber.separator}
                    </span>
                    <ContentEditable
                        className="running-invoice-number"
                        html={props.invoiceNumber}
                        onChange={onInvoiceNumberChange}
                    />
                </div>
            </div>

            <div className="intro-text">
                Für das unten stehende Projekt verrechne ich im
                Leistungszeitraum&nbsp;
                <ContentEditable html={period} onChange={onPeriodChange} />
            </div>
        </>
    );
}
