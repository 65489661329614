import React, { ReactNode, useState } from "react";
import { v4 as uuidv4 } from "uuid";

import Page, { TPage, TPageType } from "./Document";

interface PagesProps {}

export function Pages(props: PagesProps) {
    const [pages, setPages] = useState<TPage[]>([
        {
            id: uuidv4().substring(0, 7),
            pageNumber: 1,
            type: TPageType.Outvoice,
        },
    ]);

    function onAddItemClick(addAfter: string, pageType: TPageType) {
        let index = 0;
        pages.forEach((page: TPage, key: number) => {
            if (page.id === addAfter) {
                index = key;
            }
        });

        const newPage: TPage = {
            id: uuidv4().substring(0, 7),
            pageNumber: index + 1,
            type: pageType,
        };

        const pagesWithInsertedNewPage = [
            ...pages.slice(0, index + 1),
            newPage,
            ...pages.slice(index + 1),
        ];

        pagesWithInsertedNewPage.forEach((page: TPage, key: number) => {
            page.pageNumber = key + 1;
        });

        setPages(pagesWithInsertedNewPage);
    }

    function onRemoveItemClick(itemId: string) {
        // dont allow to delete last page
        if (pages.length < 2) {
            return;
        }
        setPages((pages) => {
            const pagesWithoutRemovedPage = pages.filter(
                (page) => page.id !== itemId
            );
            pagesWithoutRemovedPage.forEach((page: TPage, key: number) => {
                page.pageNumber = key + 1;
            });
            return pagesWithoutRemovedPage;
        });
    }

    function onItemChange(page: TPage) {
        setPages((pages: TPage[]): TPage[] => {
            return pages.map((editedPage: TPage): TPage => {
                if (editedPage.id === page.id) {
                    return page;
                }

                return editedPage;
            });
        });
    }

    function renderItems(): ReactNode[] {
        const result: ReactNode[] = [];

        pages.forEach((item: TPage) => {
            result.push(
                <Page
                    key={item.id}
                    page={item}
                    onAddItemClick={onAddItemClick}
                    onRemoveItemClick={onRemoveItemClick}
                    onItemChange={onItemChange}
                    pageNumber={item.pageNumber}
                    totalPagesNumber={pages.length}
                />
            );
        });

        return result;
    }

    return <>{renderItems()}</>;
}
